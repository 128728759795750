import { api } from "./BackendService";

export const create = (data: any) => api.post("backoffice/ebooks", data);
export const deleteById = (id: number) => api.delete(`backoffice/ebooks/${id}`);
export const update = (id: number, data: any) =>
  api.put(`backoffice/ebooks/${id}`, data);
export const getById = (id: number) => api.get(`backoffice/ebooks/${id}`);
export const list = (page: number, limit: number) =>
  api.get("backoffice/ebooks", { params: { page, limit } });

export const uploadVideo = (data: any) =>
  api.post("https://files.minhafono.com.br/upload-ebooks.php", data);

export const getByname = (search: any) =>
  api.get(`backoffice/ebooks/search/q`, { params: { search } });
