import { AdminForm, ProtectedPage } from "../../components";
import { AdminService } from "../../services";

export const AdminRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await AdminService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Administrador"
      breadcrumbs={[
        { name: 'Administradores', to: '/admin-users' },
        { name: 'Cadastrar Administrador', to: '/admin-users/new' }
      ]}
    >
      <AdminForm
        buttonName="Salvar"
        formTitle="Cadastrar Administrador"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Administrador Cadastrado com Sucesso!" />
    </ProtectedPage>
  );
};
