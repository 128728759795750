import { ProtectedPage } from "../../components";

import SpecialitiesForm from "../../components/SpecialitiesForm";
import { SpecialityService } from "../../services";

export const SpecialitiesRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await SpecialityService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Especialidades"
      breadcrumbs={[
        { name: "Especialidades", to: "/specialities" },
        { name: "Cadastrar Especialidade", to: "/specialities/new" },
      ]}
    >
      <SpecialitiesForm
        buttonName="Salvar"
        formTitle="Criar Especialidade"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Especialidade Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
