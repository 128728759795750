import React from "react";
import { useParams } from "react-router-dom";

import { ProtectedPage } from "../../components";

import SpecialitiesForm from "../../components/SpecialitiesForm";
import { SpecialityService } from "../../services";

export const SpecialitiesEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await SpecialityService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await SpecialityService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Especialidades"
      breadcrumbs={[
        { name: "Especialidades", to: "/specialities" },
        { name: "Editar Especialidade", to: "/specialities/:id/edit" },
      ]}
    >
      <SpecialitiesForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Especialidade"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Especialidade atualizado com sucesso"
      />
    </ProtectedPage>
  );
};
