import React from "react";
import { format, parseISO } from "date-fns";
import { pt } from "date-fns/locale";

export interface MFDateFormatProps {
  date: string
}

export const MFDateFormat: React.FunctionComponent<MFDateFormatProps> = (props: MFDateFormatProps) => (
  <span>
    {
      format(parseISO(props.date), "Pp", {
        locale: pt,
      })
    }
  </span>
)