import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  TextField,
  Paper,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Search as SearchIcon,
  Send as SendIcon,
} from "@material-ui/icons";
import { ProtectedPage, MFDateFormat } from "../../components";
import { EmailService, ProfessionalsService } from "../../services";

import { spacing } from "@material-ui/system";
import moment from 'moment';
import Swal from 'sweetalert2';

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);
function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState<any>({});
  const [deleteId, setDeleteId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);


  const [subject, setSubject] = React.useState('');
  const [body, setBody] = React.useState('');
  const [mails, setMails] = React.useState([]);

  const handleClickOpenDialog = (s: any, m: any) => {
    setSubject(s)
    setBody(m)
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onHandlerMail = async (e: any) => {
    setMails(e.target.value.split(/(\s+)/).filter(function (e: any) { return e.trim().length > 0; }))
  };

  const handleSendDialog = async () => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
      didOpen: async (toast) => {
        setOpenDialog(false);
        const data = {
          email: mails,
          subject: subject,
          body: body
        }

        await EmailService.sendMail(data);
      },
      willClose: async () => {
      },
    });

    Toast.fire({
      icon: 'success',
      title: 'E-mail enviado com sucesso!',
    });
  };




  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await EmailService.deleteById(deleteId);
    handleClose();
    const resList = await EmailService.list(page + 1, 6);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await EmailService.list(page + 1, 6);
      setData(resList.data);
    })();
  }, [page]);

  moment.locale('pt-br');

  const handleSubmit = async () => {
    console.log('asdas')

  };

  return (
    <Grid container={true} spacing={6}>
      <Grid item={true} xs={12}>
        <Paper>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza que deseja excluir?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemove} color="primary">
                Sim
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus={true}>
                Não
              </Button>
            </DialogActions>
          </Dialog>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableCell>Descrição</TableCell>
                <TableCell align="center">Criação / Atualização</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableHead>
              <TableBody>
                {data?.items?.map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover={true}
                      key={`${row.id}-${index}`}
                      tabIndex={1}
                    >
                      <TableCell component="th" scope="row">
                        <Customer>
                          <Typography>
                            <strong>Campanha: </strong>{row?.title}
                            <br />
                            <strong>Assunto: </strong>{row?.subtitle}
                          </Typography>
                        </Customer>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          {moment(row?.updated_at).format('D/M/YYYY, hh:mm')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="edit"
                          component={NavLink}
                          exact={true}
                          to={`/mailing/${row.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={(id) => handleRemoveAction(row.id, row.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleClickOpenDialog(row?.subtitle, row?.body_text)}
                        >
                          <SendIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={data?.meta?.totalItems}
            rowsPerPage={6}
            page={page}
            onChangePage={handleChangePage}
          />
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{subject}</DialogTitle>
            <DialogContent>
              <DialogContentText>
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Endereços de e-mail (seperar por espaço)"
                type="email"
                fullWidth
                variant="standard"
                onChange={(e) => onHandlerMail(e)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Fechar</Button>
              <Button onClick={handleSendDialog}>Enviar</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>
    </Grid>
  );
}

export const EmailsIndexPage = () => {
  return (
    <ProtectedPage
      title="Mailing"
      breadcrumbs={[{ name: "Mailing", to: "/mailing" }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/mailing/new"
          variant="contained"
        >
          Cadastrar E-mail
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
