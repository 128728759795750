import { ProfessionalsForm, ProtectedPage } from "../../components";
import { EmailService } from "../../services";
import { useParams } from "react-router-dom";
import React from "react";
import { EmailsForm } from "../../components/EmailsForm";

export const EmailEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await EmailService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    console.log(form)
    await EmailService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar E-mail"
      breadcrumbs={[
        { name: 'Mailing', to: '/mailing' },
        { name: 'Editar E-mail', to: '/mailing/:id/edit' }
      ]}
    >
      <EmailsForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar E-mail"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="E-mail Atualizado com Sucesso!"
      />
    </ProtectedPage>
  );
};
