import React from "react";

import { Redirect } from "react-router-dom";
import { AuthService } from "../services";

interface AuthGuardType {
  children: React.ReactNode;
}

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const [auth, setAuth] = React.useState<any>();
  const [isAuthResolved, setIsAuthResolved] = React.useState(false);
  const [tokenExpired, setTokenExpired] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const user = await AuthService.getCurrentUser();
      if (user) {
        setAuth(user);

        const decodedJwt = parseJwt(user.refreshToken);
        const expiredtoken = decodedJwt.exp < new Date().getTime() / 1000;
        if (expiredtoken) {
          setTokenExpired(true);
          localStorage.removeItem("auth");
        }
      }

      setIsAuthResolved(true);
    })();
  }, []);

  if (isAuthResolved) {
    if (tokenExpired) {
      return <Redirect to="/auth/sign-in" />;
    }

    if (auth) {
      return children;
    }

    return <Redirect to="/auth/sign-in" />;
  }

  return null;
}

export default AuthGuard;
