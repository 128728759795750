import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
    Card as MuiCard,
} from "@material-ui/core";

const Card = styled(MuiCard)(spacing);

interface CardProps {
    url: string;
}

export const CardComponent: React.FC<CardProps> = ({
    url,
}) => {
    const [source, setSource] = React.useState("");

    React.useEffect(() => {
        setSource(url || "null");
    }, [url]);

    const Root = styled.div`
        width: 120px;
        height: 80px;
        background-image: url(${source !== "null" ? source : '/static/img/brands/no-thumb.jpeg'});
        background-size: cover;
        background-position: center;
    `;

    return (
        <Card>
            <Root />
        </Card>
    );
};
