import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField, RichText } from ".";
import { spacing, SpacingProps } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface EmailsFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const EmailsForm: React.FC<EmailsFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [subtitle, setSubtitle] = React.useState("");
  const [body, setBody] = React.useState("");
  const [status, setStatus] = React.useState({ sent: false });

  React.useEffect(() => {
    setTitle(data?.title || "");
    setSubtitle(data?.subtitle || "");
    setBody(data?.body_text || "");
  }, [data]);

  const resetForm = () => {
    setTitle("");
    setSubtitle("");
    setBody("");
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const formData = {
      title,
      subtitle,
      body_text: body,
      isActive: true
    };

    setSubmitting(true);

    try {
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const onTitleChange = (event: any) => setTitle(event.target.value);
  const onSubtitleChange = (event: any) => setSubtitle(event.target.value);
  const onBodyChange = (event: any) => setBody(event);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <MFTextField
                  error={!!errors?.title}
                  fullWidth={true}
                  helperText={errors?.title}
                  label="Campanha"
                  my={2}
                  name="title"
                  onChange={onTitleChange}
                  placeholder="Digite o primeiro título"
                  value={title}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <MFTextField
                  error={!!errors?.subtitle}
                  fullWidth={true}
                  helperText={errors?.subtitle}
                  label="Assunto"
                  my={2}
                  name="subtitle"
                  onChange={onSubtitleChange}
                  placeholder="Digite o sub título"
                  type="text"
                  value={subtitle}
                  variant="outlined"
                />
              </Grid>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <RichText value={body}
                  onChange={onBodyChange}
                ></RichText>
              </Grid>
              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
