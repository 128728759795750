import React, { useEffect, useState, FC } from 'react';
import { EbooksViewService } from '../services';

interface IEbooksViewProps {
    ebookId: any;
}
const EbooksViewCount: FC<IEbooksViewProps> = ({ ebookId }) => {
    const [count, setCount] = useState<number>(0);
    console.log(ebookId)
    useEffect(() => {
        const fetchCount = async () => {
            try {
                const resList = await EbooksViewService.get(ebookId);
                setCount(resList.data);
            } catch (error) {
                console.error('Failed to fetch ebook view count', error);
            }
        };

        fetchCount();
    }, [ebookId]);

    return (
        <div>
            {count}
        </div>
    );
};

export default EbooksViewCount;