import React from "react";
import { Redirect } from "react-router-dom";
import { AuthService } from "../../services";

export const SignOutPage = () => {
  React.useEffect(() => {
    AuthService.signOut();
  }, []);

  return <Redirect to="/auth/sign-in" />;
};
