import { api } from "./BackendService";

export const create = (data: any) =>
  api.post("backoffice/attendence-platforms", data);
export const deleteById = (id: number) =>
  api.delete(`backoffice/attendence-platforms/${id}`);
export const edit = (id: number, data: any) =>
  api.patch(`backoffice/attendence-platforms/${id}`, data);
export const getById = (id: number) =>
  api.get(`backoffice/attendence-platforms/${id}`);
export const list = (page: number, limit: number) =>
  api.get("backoffice/attendence-platforms", { params: { page, limit } });
