import { AttendencePlataformService } from "../../services";
import { AttendencePlataformForm, ProtectedPage } from "../../components";
import { useParams } from "react-router-dom";
import React from "react";

export const AttendencePlataformsEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await AttendencePlataformService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await AttendencePlataformService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Plataforma de Atendimento"
      breadcrumbs={[
        { name: 'Plataformas de Atendimento', to: '/attendence-plataforms' },
        { name: 'Editar Plataforma de Atendimento', to: '/attendence-plataforms/:id/edit' }
      ]}
    >
      <AttendencePlataformForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Plataforma de Atendimento"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Plataforma Atualizada com Sucesso!"
      />
    </ProtectedPage>
  );
};
