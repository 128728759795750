import React from "react";
import { ProtectedPage, VideosForm } from "../../components";
import { VideosService } from "../../services";
import { useParams } from "react-router-dom";

export const VideoRegisterFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await VideosService.getById(id);
      setData(resList.data);
    })();
  }, []);


  const handleSubmit = async (form: any) => {
    await VideosService.update(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Vídeo"
      breadcrumbs={[
        { name: "Vídeos", to: "/videos" },
        { name: "Editar Vídeo", to: "/videos/:id/edit" },
      ]}
    >
      <VideosForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Vídeo"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Vídeo Editado com Sucesso!"
      />

    </ProtectedPage>
  );
};
