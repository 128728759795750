import axios from "axios";
import { getCurrentUser, refreshToken } from "./AuthService";

const BASE_URL_MAP: { [key: string]: string } = {
  localhost: "http://localhost:8000",
  "backoffice.minhafono.com.br": `${window.location.protocol}//pro-fono-backend.herokuapp.com`,
};

const baseURL = BASE_URL_MAP[window.location.hostname];

const api = axios.create({
  baseURL,
  withCredentials: true,
});

api.interceptors.request.use(
  async (config) => {
    const currentUser = await getCurrentUser();

    if (!currentUser) return config;

    config.headers.Authorization = `Bearer ${currentUser.accessToken}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      const currentUser = await getCurrentUser();
      if (currentUser !== null) {
        await refreshToken(currentUser.refreshToken);
      }
    }

    return Promise.reject(error);
  }
);

export { api, baseURL };
