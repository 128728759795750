import { api } from "./BackendService";

export const create = (data: any) =>
  api.post("backoffice/videos-playlists", data);
export const deleteById = (id: number) =>
  api.delete(`backoffice/videos-playlists/${id}`);
export const update = (id: number, data: any) =>
  api.put(`backoffice/videos-playlists/${id}`, data);
export const getById = (id: number) => api.get(`backoffice/videos/${id}`);
export const list = (page: number, limit: number, id: any) =>
  api.get("backoffice/videos-playlists", { params: { page, limit, id } });
export const updateOrder = (data: any) =>
  api.put(`backoffice/videos-playlists/order`, data);
