import React from "react";

export const HomePage = () => {
  return (
    <img
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
      src="/static/img/welcome.jpeg"
      alt="Imagem de boas vindas"
    />
  );
}
