import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  Avatar as MuiAvatar,
  Box as MuiBox,
  Divider as MuiDivider,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  TextField,
  Chip,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { ProtectedPage, MFDateFormat } from "../../components";
import { ProfessionalsService } from "../../services";

import { spacing } from "@material-ui/system";
import moment from 'moment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Área',
  'Data de cadastro',
  'Data de término',
  'Ordem alfabética',
];


function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState<any>({});
  const [deleteId, setDeleteId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<any>();
  const [search, setSearch] = React.useState<any>('%');
  const [personName, setPersonName] = React.useState([]);
  const [filter, setFilter] = React.useState([]);
  const [active, setActive] = React.useState(true);

  const handleChange = async (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: async (toast) => {
      },
      willClose: async () => {
      },
    });

    Toast.fire({
      icon: 'info',
      title: 'Carregando...',
    });

    setFilter(value)
    const resList = await ProfessionalsService.supervisor(page + 1, 20, search, value, false, active);
    if (resList.status === 200) {
      Toast.close()
    }
    setData(resList.data);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await ProfessionalsService.deleteById(deleteId);
    handleClose();
    const resList = await ProfessionalsService.supervisor(page + 1, 20, search, filter, true, false);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await ProfessionalsService.supervisor(page + 1, 20, search, filter, true, active);
      setData(resList.data);
    })();
  }, []);

  const handlerOnBlur = async (e: any) => {
    setSearch(e.target.value)
    const resList = await ProfessionalsService.supervisor(page + 1, 20, search, [], true, true);
    setData(resList.data);
  }


  return (
    <Grid container={true} spacing={6}>
      <Grid item={true} xs={12}>
        <Paper>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <span>
              <InputLabel id="demo-multiple-checkbox-label">Pesquisar</InputLabel>
              <TextField inputProps={{
                autoComplete: 'off'
              }} type='text' autoComplete='off' label="Pesquisar" variant="outlined" onChange={handlerOnBlur} />
            </span>
            <span>
              <InputLabel id="demo-multiple-checkbox-label">Filtro</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </span>
          </div>


          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza que deseja excluir?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemove} color="primary">
                Sim
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus={true}>
                Não
              </Button>
            </DialogActions>
          </Dialog>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableCell style={{ width: '200px' }}>Nome / Usuário</TableCell>
                <TableCell style={{ width: '200px' }}>Foto</TableCell>
                <TableCell style={{ width: '200px' }}>Whatsapp</TableCell>
                <TableCell style={{ width: '200px' }} align="center">Criação / Atualização</TableCell>
                <TableCell style={{ width: '200px' }} align="right">Ações</TableCell>
              </TableHead>
              <TableBody>
                {data?.results?.map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover={true}
                      key={`${row.id}-${index}`}
                      tabIndex={1}
                    >
                      <TableCell>
                        <Typography>
                          {row?.name}
                          <br />
                          E-mail: {row?.email}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Customer>
                          <Typography>
                            {row?.picture ? 'Sim' : 'Não'}
                          </Typography>
                        </Customer>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Customer>
                          <Typography>
                            {row?.whatsapp}
                          </Typography>
                        </Customer>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>
                          <MFDateFormat date={row.createdAt} />
                          <br />
                          {row.updatedAt &&
                            <MFDateFormat date={row.updatedAt} />}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="edit"
                          component={NavLink}
                          exact={true}
                          to={`/professionals/${row.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={(id) => handleRemoveAction(row.id, row.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }).sort()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[30]}
            component="div"
            count={data?.totalResults}
            rowsPerPage={30}
            page={page}
            onChangePage={handleChangePage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export const ProfessionalSuperIndexPage = () => {
  return (
    <ProtectedPage
      title="Supervisores"
      breadcrumbs={[{ name: "Profissionais", to: "/professionals" }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/supervisores/new"
          variant="contained"
        >
          Cadastrar Profissional
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
