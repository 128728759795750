import { api } from "./BackendService";

export const create = (data: any) => api.post("backoffice/admin-users", data);
export const deleteById = (id: number) =>
  api.delete(`backoffice/admin-users/${id}`);
export const edit = (id: number, data: any) =>
  api.patch(`backoffice/admin-users/${id}`, data);
export const getById = (id: number) => api.get(`backoffice/admin-users/${id}`);
export const list = (page: number, limit: number) =>
  api.get("backoffice/admin-users", { params: { page, limit } });
