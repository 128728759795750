import { api } from "./BackendService";

export const create = (data: any) => api.post("backoffice/playlists", data);
export const listAll = () => api.get("backoffice/playlists");
export const deleteById = (id: number) =>
  api.delete(`backoffice/playlists/${id}`);
export const update = (id: number, data: any) =>
  api.put(`backoffice/playlists/${id}`, data);
export const getById = (id: number) => api.get(`backoffice/playlists/${id}`);
export const list = (page: number, limit: number) =>
  api.get("backoffice/playlists", { params: { page, limit } });
