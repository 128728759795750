import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField } from ".";
import { spacing, SpacingProps } from "@material-ui/system";
import { useEffect } from "react";
import React from "react";
import styled from "styled-components";
import Select from 'react-select'
import { AttendenceMethodsService } from "../services";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper as MuiPaper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";


const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}
interface SpecialitiesFromProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

const SpecialitiesForm: React.FC<SpecialitiesFromProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [name, setName] = React.useState("");
  const [status, setStatus] = React.useState({ sent: false });
  const [state, setState] = React.useState<ArrayObjectSelectState>({
    selectedAttendenceMthod: null,
  });
  const [validations, setValidations] = React.useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    (async () => {
      const resList = await AttendenceMethodsService.list(1, 6)
      state.selectedAttendenceMthod = { value: data?.attendenceMethod?.id, label: data?.attendenceMethod?.name }
      setName(data?.name || "");
    })();
  }, [data]);

  const onNameChange = (event: any) => setName(event.target.value);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const formData = {
      name,
      attendence_method_id: state.selectedAttendenceMthod?.value
    };
    setSubmitting(true);

    try {
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      console.log(err?.response?.data);
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    setName("");
  };


  interface AttendenceMthod {
    label: string;
    value: string;
  }
  interface ArrayObjectSelectState {
    selectedAttendenceMthod: AttendenceMthod | null;
  }
  const attendenceMthod: AttendenceMthod[] = [
    {
      label: "PRESENCIAL",
      value: "674a6870-020a-4119-9b23-92614004f25b",
    },
    {
      label: "TELEATENDIMENTO",
      value: "7ad0de9d-68eb-457a-aad8-fcd823edc34e",
    },]

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid
                item={true}
                md={6}
                style={{ marginBottom: "16px", marginTop: "24px" }}
              >
                <MFTextField
                  error={!!errors?.name}
                  fullWidth={true}
                  helperText={errors?.name}
                  label="Nome da Especialidade"
                  my={2}
                  name="name"
                  onChange={onNameChange}
                  placeholder="Digite o nome da Especialidade"
                  value={name}
                  variant="outlined"
                />
                {console.log('testando123', state.selectedAttendenceMthod)}
                <Select
                  onChange={(option: AttendenceMthod | null) => {
                    setState({ selectedAttendenceMthod: option });
                  }}
                  value={state.selectedAttendenceMthod}
                  getOptionLabel={(attendenceMthod: AttendenceMthod) => attendenceMthod.label}
                  getOptionValue={(attendenceMthod: AttendenceMthod) => attendenceMthod.value}
                  options={attendenceMthod}
                  placeholder="Selecione"
                  isClearable={true}
                  backspaceRemovesValue={true}
                  id="attendence_method_id"
                  name="attendence_method_id"
                />
              </Grid>

              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
export default SpecialitiesForm;
