import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField } from ".";
import { spacing, SpacingProps } from "@material-ui/system";
import React from "react";
import styled from "styled-components";
import Select from 'react-select'
import urlSlug from 'url-slug'
import { VideosService } from "../services";

import {
  Player,
  BigPlayButton,
  ControlBar,
  PlaybackRateMenuButton,
  LoadingSpinner,
} from "video-react";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";


const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface PlaylistFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const PlaylistForm: React.FC<PlaylistFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [status, setStatus] = React.useState<ArrayObjectSelectState>({
    selectedStatusMethod: null,
  });
  const [statusForm, setStatusForm] = React.useState({ sent: false });

  React.useEffect(() => {
    setTitle(data?.title || "");
    setDescription(data?.description || "");
    status.selectedStatusMethod = { value: data?.status || "", label: data?.status || "" }
  }, [data]);

  const resetForm = () => {
    setTitle("");
    setDescription("");
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const formData = {
      title,
      description,
      status: status.selectedStatusMethod?.value
    };

    setSubmitting(true);

    try {
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatusForm({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatusForm({ sent: false });
      setSubmitting(false);
    }
  };

  interface StatusMthod {
    label: string;
    value: string;
  }
  interface ArrayObjectSelectState {
    selectedStatusMethod: StatusMthod | null;
  }
  const statusMthod: StatusMthod[] = [
    {
      label: "Público",
      value: "Público",
    },
    {
      label: "Privado",
      value: "Privado",
    },]

  const onTitleChange = (event: any) => setTitle(event.target.value);
  const onDescriptionChange = (event: any) => setDescription(event.target.value);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {statusForm && statusForm.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <Grid
              container
              spacing={8}
              direction={"row"}
            >
              <Grid item xs={12} >
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <Grid item xs={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.title}
                      fullWidth={true}
                      helperText={errors?.title}
                      label="Título"
                      my={2}
                      name="title"
                      onChange={onTitleChange}
                      placeholder="Título do vídeo"
                      value={title}
                      variant="outlined"
                    />
                    <MFTextField
                      error={!!errors?.description}
                      fullWidth={true}
                      helperText={errors?.description}
                      label="Descrição"
                      my={2}
                      name="description"
                      onChange={onDescriptionChange}
                      placeholder="Descrição do vídeo"
                      type="text"
                      value={description}
                      variant="outlined"
                      multiline={true}
                      rows={"15"}
                    />
                    <Select
                      onChange={(option: StatusMthod | null) => {
                        setStatus({ selectedStatusMethod: option });
                      }}
                      value={status.selectedStatusMethod}
                      getOptionLabel={(statusMthod: StatusMthod) => statusMthod.label}
                      getOptionValue={(statusMthod: StatusMthod) => statusMthod.value}
                      options={statusMthod}
                      placeholder="Selecione"
                      isClearable={true}
                      backspaceRemovesValue={true}
                      id="status"
                      name="status"
                      my={2}
                    />
                  </Grid>
                  <Button
                    onClick={submitForm}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={4}
                  >
                    {buttonName}
                  </Button>
                </form>
              </Grid>
            </Grid>

          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
