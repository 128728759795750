import { ProfessionalsForm, ProtectedPage } from "../../components";
import { ProfessionalsService } from "../../services";
import { useParams } from "react-router-dom";
import React from "react";

export const ProfessionalEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await ProfessionalsService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await ProfessionalsService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Profissional"
      breadcrumbs={[
        { name: 'Profissional', to: '/professionals' },
        { name: 'Editar Profissional', to: '/professionals/:id/edit' }
      ]}
    >
      <ProfessionalsForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Profissional"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Profissional Atualizado com Sucesso!"
      />
    </ProtectedPage>
  );
};
