import React from "react";
import { PlaylistForm, ProtectedPage, VideosForm, CardComponent } from "../../components";
import { useParams } from "react-router-dom";
import { PlaylistsService, VideosPlaylistsService, VideosService } from "../../services";
import styled from "styled-components";
import axios from 'axios';
import { NavLink } from "react-router-dom";

import { spacing } from "@material-ui/system";

import AsyncSelect from "react-select/async";



import Nestable from 'react-nestable';

import 'react-nestable/dist/styles/index.css';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  VisibilityOutlined,
  VisibilityOff
} from "@material-ui/icons";


import Swal from 'sweetalert2';

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
`;


export const PlaylistEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const [videos, setVideos]: any = React.useState();
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const videosList = await VideosPlaylistsService.list(1, 20, id);
      setVideos(videosList.data);

      const resList = await PlaylistsService.getById(id);
      setData(resList.data);
    })();
  }, []);


  const handleSubmit = async (form: any) => {
    await PlaylistsService.update(id, form);
  };



  function EnhancedTable() {
    const [page, setPage] = React.useState(0);
    const [data, setData] = React.useState<any>({});
    const [deleteId, setDeleteId] = React.useState<any>();
    const [open, setOpen] = React.useState(false);
    const [isClearable, setIsClearable] = React.useState(true);
    const hiddenFilePictureInput: any = React.useRef(null);


    const filter = async (inputValue: any) => {
      const array: any = [];
      try {
        const resp = await VideosService.getByname(inputValue);
        console.log(resp)
        if (inputValue.length > 0)
          resp.data.forEach((element: any) => {
            console.log(element)
            if (element?.title !== "")
              array.push({
                label: `${element?.title}`,
                value: `${element?.id}`,
                name: `${element?.title}`,
              });
          });

        return array.filter((i: any) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      } catch (error) { }
    };

    const loadOptions = (inputValue: any, callback: any) => {
      setTimeout(async () => {
        callback(await filter(inputValue));
      }, 1000);
    };

    const handleChangeSelect = async (e: any) => {

      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        timer: 1800,
        timerProgressBar: true,
        didOpen: async (toast) => {
          let obs = {
            "videos_id": parseInt(e?.value),
            "playlists_id": parseInt(id)
          }
          await VideosPlaylistsService.create(obs);
        },
        willClose: async () => {
          const videosList = await VideosPlaylistsService.list(1, 20, id);
          setVideos(videosList.data);
        },
      });

      Toast.fire({
        icon: 'success',
        title: 'Vídeo adicionado a playlist!',
      });

    };

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };

    const handleRemove = async () => {
      await VideosPlaylistsService.deleteById(deleteId);
      handleClose();
      const videosList = await VideosPlaylistsService.list(1, 20, id);
      setVideos(videosList.data);
    };

    const handleRemoveAction = (id: any) => {
      setOpen(true);
      setDeleteId(id);
    };

    const handleClose = () => {
      setOpen(false);
      setDeleteId(null);
    };

    const renderItem = (row: any) => {
      console.log(row)
      return (
        <span >
          <TableRow
            hover={true}
            key={`${row.videos?.id}-${0}`}
            tabIndex={1}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'nowrap'
            }}
          >
            <TableCell>
              <Customer>
                <CardComponent
                  url={row?.item?.videos?.thumbnail}
                />
              </Customer>
            </TableCell>
            <TableCell component="th" scope="row" style={{ width: "38%" }}>
              <Customer>
                <Typography>
                  <strong>{row?.item?.videos?.title}</strong>
                  <br />


                  <small>
                    {row?.item?.videos?.description?.length <= 25 && <span dangerouslySetInnerHTML={{ __html: row?.item?.videos?.description }} />
                    }
                    {row?.item?.videos?.description?.length > 200 && <span title={row?.item?.videos?.description}>

                      <span dangerouslySetInnerHTML={{ __html: row?.item?.videos?.description.substr(0, 200) }} />
                    </span>}
                  </small>


                </Typography>
              </Customer>
            </TableCell>
            <TableCell align="left">
              <Typography className="main">
                {row?.item?.videos?.status === "Público" ? (<VisibilityOutlined color="primary" />) : (<VisibilityOff color="error" />)} &nbsp;  {row?.item?.videos?.status}
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>
                <small>{row?.item?.videos?.createdAt}<br />Publicado</small>
              </Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton
                aria-label="edit"
                component={NavLink}
                exact={true}
                to={`/videos/${row.item?.videos?.id}/edit`}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={(id) => handleRemoveAction(row.item?.id)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </span>
      );
    };

    const changeDragItem = (e: any) => {
      VideosPlaylistsService.updateOrder(e)
      console.log(JSON.stringify(e));
    }

    return (
      <Grid container={true} spacing={6}>

        <Grid item={true} xs={12}>
          <Typography variant="h4" gutterBottom={true} display="inline">
            Vídeos
          </Typography>

          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            onChange={handleChangeSelect}
            noOptionsMessage={() => "Nenhum vídeo encontrada."}
            openMenuOnClick={false}
            loadingMessage={() => "Carregando..."}
            placeholder="Pesquise para adicionar um vídeo..."
            isClearable={isClearable}
          />
          <br />
          <Paper>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle id="alert-dialog-title">{`Alerta!`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Deseja remover da playlists?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRemove} color="primary">
                  Sim
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus={true}>
                  Não
                </Button>
              </DialogActions>
            </Dialog>
            <Nestable
              items={videos?.results}
              renderItem={renderItem}
              onChange={changeDragItem}
            />

          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <ProtectedPage
      title="Editar Playlist"
      breadcrumbs={[
        { name: "Vídeos", to: "/videos" },
        { name: "Playlist", to: "/videos/playlists" },
        { name: "Editar Playlist", to: "/videos/playlists/:id/edit" },
      ]}
    >
      <PlaylistForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Playlist"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Playlist Editado com Sucesso!"
      />

      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
