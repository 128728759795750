import { ProfessionalsForm, ProtectedPage } from "../../components";
import { EmailsForm } from "../../components/EmailsForm";
import { EmailService } from "../../services";

export const EmailRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await EmailService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar E-mail"
      breadcrumbs={[
        { name: "Mailing", to: "/mailing" },
        { name: "Cadastrar E-mail", to: "/mailing/new" },
      ]}
    >
      <EmailsForm
        buttonName="Salvar"
        formTitle="Cadastrar E-mail"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="E-mail Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
