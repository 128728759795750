import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField } from "../components";
import { spacing, SpacingProps } from "@material-ui/system";
import React from "react";
import styled from "styled-components";
import InputMask from 'react-input-mask';
import { ProfessionalsService } from "../services";
import Select from "react-select";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  Paper as MuiPaper,
  Typography,
  TextField,
  Switch,
  Link,
} from "@material-ui/core";
import { useParams } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface ProfessionalsFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const ProfessionalsForm: React.FC<ProfessionalsFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [name, setName] = React.useState("");
  const [crfa, setCrfa] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState({ sent: false });
  const [whatsapp, setWhatsapp] = React.useState("");
  const [expertise, setExpertise] = React.useState("");
  const [curriculum, setCurriculum] = React.useState("");
  const [supervisor, setSupervisor] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [imageForm, setImageForm] = React.useState("");

  React.useEffect(() => {
    setConfirmPassword(data?.confirmPassword || "");
    setEmail(data?.email || "");
    setName(data?.name || "");
    setCrfa(data?.crfa || "");
    setPassword(data?.password || "");
    setWhatsapp(data?.whatsapp || "");
    setExpertise(data?.expertise || "");
    setCurriculum(data?.curriculum || "");
    setSupervisor(data?.supervisor || false);
    setTitle(data?.title || "");
    setImage(data?.picture || "");
  }, [data]);

  const resetForm = () => {
    setConfirmPassword("");
    setEmail("");
    setName("");
    setPassword("");
    setWhatsapp("");
    setExpertise("");
    setCurriculum("");
    setSupervisor(false);
    setTitle("");
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const formData = {
      confirmPassword,
      email,
      crfa,
      name,
      password: password,
      whatsapp,
      expertise,
      curriculum,
      supervisor,
      title,
    };

    setSubmitting(true);

    try {
      console.log(formData)
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const onConfirmPasswordChange = (event: any) =>
    setConfirmPassword(event.target.value);
  const onEmailChange = (event: any) => setEmail(event.target.value);
  const onNameChange = (event: any) => setName(event.target.value);
  const onCrfaChange = (event: any) => setCrfa(event.target.value);
  const onPasswordChange = (event: any) => setPassword(event.target.value);
  const onWhatsapp = (event: any) => setWhatsapp(event.target.value);
  const onExpertise = (event: any) => setExpertise(event.target.value);
  const onCurriculo = (event: any) => setCurriculum(event.target.value);
  const onSupervisor = (event: any) => {
    setSupervisor(event.target.checked);
  }
  const onTitle = (event: any) => {
    setTitle(event.value);
  }

  const profTitle = [
    {
      label: "GRADUAÇÃO",
      value: "GRADUAÇÃO",
      name: "title",
    },
    {
      label: "ESPECIALIZAÇÃO",
      value: "ESPECIALIZAÇÃO",
      name: "title",
    },
    {
      label: "MESTRADO",
      value: "MESTRADO",
      name: "title",
    },
    {
      label: "DOUTORADO",
      value: "DOUTORADO",
      name: "title",
    },
    {
      label: "PÓS-DOUTORADO",
      value: "POS-DOUTORADO",
      name: "title",
    },
  ];

  let get: any = useParams();
  console.log(get.id)

  const formData = new FormData();
  formData.append("file", imageForm);
  formData.append("path", `user/${get.id}`);

  if (imageForm !== "") {
    ProfessionalsService.updatePhoto(get.id, formData);
  }

  const handleFile = (e: any) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setImageForm(e.target.files[0]);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={12}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4} style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    style={{ textAlign: "center", marginTop: "35px" }}
                  >
                    {image ? (
                      <>
                        <div style={{ width: '200px', height: '200px', backgroundImage: "url(" + image + ")", backgroundSize: 'cover', borderRadius: '10px' }}>

                        </div></>
                    ) : (
                      <img
                        src={"/static/img/avatar-default.png"}
                        alt="Imagem"
                        width="150"
                        height="150"
                      />
                    )}
                    <br />
                    <input
                      accept="image/*"
                      name="image"
                      onChange={(e) => handleFile(e)}
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="contained-button-file">
                      <Link
                        color="primary"
                        component="span"
                        className={""}
                        style={{ cursor: "pointer" }}
                      >
                        Editar Foto
                      </Link>
                    </label>
                  </Grid>
                </Grid>
                <Grid item xs={4}>

                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.name}
                      fullWidth={true}
                      helperText={errors?.name}
                      label="Nome"
                      my={2}
                      name="name"
                      onChange={onNameChange}
                      placeholder="Digite o primeiro nome"
                      value={name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.email}
                      fullWidth={true}
                      helperText={errors?.email}
                      label="Email"
                      my={2}
                      name="email"
                      onChange={onEmailChange}
                      placeholder="Digite o email"
                      type="email"
                      value={email}
                      variant="outlined"
                    />
                  </Grid>
                  {/*  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.crfa}
                      fullWidth={true}
                      helperText={errors?.crfa}
                      label="CRFA"
                      my={2}
                      name="crfa"
                      onChange={onCrfaChange}
                      placeholder="Digite o crfa"
                      value={crfa}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.password}
                      fullWidth={true}
                      helperText={errors?.password}
                      label="Senha"
                      my={2}
                      name="password"
                      onChange={onPasswordChange}
                      placeholder="Digite a senha"
                      type="password"
                      value={password}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.confirmPassword}
                      fullWidth={true}
                      helperText={errors?.confirmPassword}
                      label="Confirme sua senha"
                      my={2}
                      name="confirmPassword"
                      onChange={onConfirmPasswordChange}
                      placeholder="Confirme a senha"
                      type="password"
                      value={confirmPassword}
                      variant="outlined"
                    />
                  </Grid> */}
                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <InputMask
                      mask="(99) 99999-9999"
                      id="whatsapp"
                      name="whatsapp"
                      type="tel"
                      error={!!errors?.crfa}
                      helperText={errors?.crfa}
                      label="WhatsApp"
                      my={2}
                      onChange={onWhatsapp}

                      value={whatsapp}
                    >
                      {() => (
                        <TextField
                          name="whatsapp"
                          style={{ marginTop: "16px", width: "100%" }}
                          variant="outlined"
                          placeholder="Whatsapp"
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <Select
                      /*   styles={selectTitle ? customStylesDefault : customStyles} */
                      placeholder="Titulação máxima"
                      id="title"
                      name="title"
                      options={profTitle}
                      isSearchable={false}
                      onChange={(e) => onTitle(e)}
                      value={profTitle.find(
                        (profTitle) => profTitle.value === title
                      )}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>

                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <MFTextField
                      error={!!errors?.crfa}
                      fullWidth={true}
                      helperText={errors?.crfa}
                      label="Expertise"
                      my={2}
                      name="expertise"
                      onChange={onExpertise}
                      placeholder="Digite a Expertise"
                      value={expertise}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item={true} md={8} style={{ marginBottom: "16px" }}>
                    <TextField
                      id="outlined-basic"
                      label="Currículo"
                      variant="outlined"
                      multiline
                      name="curriculo"
                      rows={15}
                      onChange={onCurriculo}
                      value={curriculum}
                      fullWidth={true}
                    />
                  </Grid>

                  Supervisor
                  <Switch checked={supervisor} onChange={(e) => onSupervisor(e)} />

                </Grid>
              </Grid>

              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
