import { AttendenceMethodsForm, ProtectedPage } from "../../components";
import { AttendenceMethodsService } from "../../services";

export const AttendenceMethodsRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await AttendenceMethodsService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Método de Atendimento "
      breadcrumbs={[
        { name: 'Método de Atendimento', to: '/attendence-methods' },
        { name: 'Cadastrar Método de Atendimento', to: '/attendence-methods/new' }
      ]}
    >
      <AttendenceMethodsForm
        buttonName="Salvar"
        formTitle="Cadastrar Método de Atendimento"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Método de Atendimento Cadastrado com Sucesso!" />
    </ProtectedPage>
  );
};
