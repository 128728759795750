import { AdminForm, ProtectedPage } from "../../components";
import { AdminService } from "../../services";
import { useParams } from "react-router-dom";
import React from "react";

export const AdminEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await AdminService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await AdminService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Administrador"
      breadcrumbs={[
        { name: 'Administradores', to: '/admin-users' },
        { name: 'Editar Administrador', to: '/admin-users/:id/edit' }
      ]}
    >
      <AdminForm
        data={data}
        buttonName="Atualizar"
        formTitle="Editar Administrador"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Administrador Atualizado com Sucesso" />
    </ProtectedPage>
  );
};
