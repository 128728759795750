import { api } from "./BackendService";

export const create = (data: any) => api.post("backoffice/emails", data);
export const sendMail = (data: any) => api.post("backoffice/emails/send", data);
export const deleteById = (id: number) => api.delete(`backoffice/emails/${id}`);
export const edit = (id: string, data: any) =>
  api.put(`backoffice/emails/${id}`, data);
export const getById = (id: number) => api.get(`backoffice/emails/${id}`);
export const list = (page: number, limit: number) =>
  api.get("backoffice/emails", { params: { page, limit } });
