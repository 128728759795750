import { ProfessionalsForm, ProtectedPage } from "../../components";
import { ProfessionalsService } from "../../services";

export const ProfessionalRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await ProfessionalsService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Profissional"
      breadcrumbs={[
        { name: "Profissional", to: "/professionals" },
        { name: "Cadastrar Profissional", to: "/professionals/new" },
      ]}
    >
      <ProfessionalsForm
        buttonName="Salvar"
        formTitle="Cadastrar Profissional"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Profissional Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
