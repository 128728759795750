import { AttendencePlataformForm, ProtectedPage } from "../../components";
import { AttendencePlataformService } from "../../services";

export const AttendencePlataformsRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await AttendencePlataformService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Plataformas de Atendimento"
      breadcrumbs={[
        { name: 'Plataformas de Atendimento', to: '/attendence-plataforms' },
        { name: 'Cadastrar Plataformas de Atendimento', to: '/attendence-plataforms/new' }
      ]}
    >
      <AttendencePlataformForm
        buttonName="Salvar"
        formTitle="Cadastrar Plataformas de Atendimento"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Plataforma Cadastrada com Sucesso!"
      />
    </ProtectedPage>
  );
};
