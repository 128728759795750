import { api } from "./BackendService";

export const create = (data: any) => api.post("backoffice/professionals", data);
export const deleteById = (id: number) =>
  api.delete(`backoffice/professionals/${id}`);
export const edit = (id: number, data: any) =>
  api.patch(`backoffice/professionals/${id}`, data);
export const getById = (id: number) =>
  api.get(`backoffice/professionals/${id}`);
export const list = (
  page: number,
  limit: number,
  term: any,
  filter: any,
  type: any,
    active: any
  ) =>
  api.get("backoffice/professionals", {
    params: { page, limit, term, filter, type, active },
  });

  export const listIncomplete = (
  page: number,
  limit: number,
  term: any,
  filter: any,
  type: any,
    active: any
  ) =>
  api.get("backoffice/professionals/incomplete", {
    params: { page, limit, term, filter, type, active },
  });

export const supervisor = (
  page: number,
  limit: number,
  term: any,
  filter: any,
  type: any,
  active: any
) =>
  api.get("backoffice/professionals/supervisor", {
    params: { page, limit, term, filter, type, active },
  });

export const updatePhoto = (id: any, data: any) =>
  api.put(`front/professionals/${id}`, data);
