import React from "react";
import styled from "styled-components";

import {
  TextField as MuiTextField, TextFieldProps,
} from "@material-ui/core";

import { spacing, SpacingProps } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

export const MFTextField: React.FunctionComponent<TextFieldProps & SpacingProps> = (props) => {
  return (
    <TextField
      fullWidth={true}
      {...props}
    />
  )
}

