import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import {
  Avatar as MuiAvatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
  TextField,
  Chip,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { ProtectedPage, MFDateFormat } from "../../components";
import { ProfessionalsService } from "../../services";

import { spacing } from "@material-ui/system";
import moment from "moment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
//import { CSVLink } from 'react-csv';
import * as XLSX from "xlsx";

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      margin: theme.spacing(1),
      minWidth: 140,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Área",
  "Data de cadastro",
  "Data de término",
  "Ordem alfabética",
];

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState<any>({});
  const [dataExport, setDataExport] = React.useState<any>({});
  const [deleteId, setDeleteId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<any>();
  const [search, setSearch] = React.useState<any>("%");
  const [personName, setPersonName] = React.useState([]);
  const [active, setActive] = React.useState(true);
  const [csvExport, setCsvExport] = React.useState([]);
  const [filter, setFilter] = React.useState([]);

  const handleChange = async (event: any) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: async (toast) => { },
      willClose: async () => { },
    });

    Toast.fire({
      icon: "info",
      title: "Carregando...",
    });
    setFilter(value);
    const resList = await ProfessionalsService.listIncomplete(
      page + 1,
      20,
      search,
      value,
      false,
      active
    );
    if (resList.status === 200) {
      Toast.close();
    }
    setData(resList.data);
  };

  const handleChangeStatus = async (event: any) => {
    const newStatus = event.target.value;
    setActive(newStatus);

    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timerProgressBar: true,
      didOpen: async (toast) => { },
      willClose: async () => { },
    });

    Toast.fire({
      icon: "info",
      title: "Carregando...",
    });

    try {
      const resList = await ProfessionalsService.listIncomplete(
        page + 1,
        20,
        search,
        filter,
        false,
        newStatus
      );

      if (resList.status === 200) {
        console.log(resList.data)
        setData(resList.data);
        Toast.close();
      }

      setCsvExport([]);

      const newData: any = [];
      const resListAll = await ProfessionalsService.listIncomplete(
        page + 1,
        20,
        search,
        filter,
        false,
        newStatus
      );

      resListAll?.data?.results?.forEach((row: any) => {
        newData?.push([
          row?.id,
          row?.picture,
          row?.name,
          row?.email,
          row?.whatsapp,
          row?.cpf_cnpj,
          row?.title,
          null,
          null,
          'Cadastro Incompleto',
        ]);
      });

      setCsvExport(newData);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Erro ao carregar dados",
      });
    }
  };


  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    const resList = await ProfessionalsService.listIncomplete(
      newPage + 1,
      20,
      search,
      filter,
      false,
      active
    );
    setData(resList.data);
  };

  const handleRemove = async () => {
    await ProfessionalsService.deleteById(deleteId);
    handleClose();
    const resList = await ProfessionalsService.listIncomplete(
      page + 1,
      20,
      search,
      filter,
      false,
      active
    );
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await ProfessionalsService.listIncomplete(
        page + 1,
        20,
        search,
        filter,
        false,
        active
      );
      setData(resList.data);

      const newData: any = [];
      const resListAll = await ProfessionalsService.listIncomplete(
        page + 1,
        20,
        search,
        filter,
        false,
        active
      );
      // Formatar dados e adicionar ao newData
      newData?.push([
        "Data de Criação",
        "Data de Vencimento do Plano",
        "ID",
        "Imagem",
        "Nome",
        "E-mail",
        "WhatsApp",
        "CPF/CNPJ",
        "Título",
        "Cidade",
        "Estado",
        "Status do Plano",
      ]);
      resListAll?.data?.results?.forEach((row: any) => {
        newData?.push([
          "asd",
          "asd",
          row?.id,
          row?.picture,
          row?.name,
          row?.email,
          row?.whatsapp,
          row?.cpf_cnpj,
          row?.title,
          null,
          null,
          null,
        ]);
      });
      setCsvExport(newData);
    })();
  }, []);

  const handlerOnBlur = async (e: any) => {
    setSearch(e.target.value);
    const resList = await ProfessionalsService.listIncomplete(
      page + 1,
      20,
      e.target.value,
      filter,
      false,
      active
    );
    setData(resList.data);
  };

  const getStatusLabel = (row: {
    professionalSpecialities: any;
    clinicAddresses: { city: any }[];
    formation: any;
    professionalsPlans: { isActive: any }[];
  }) => {
    if (
      !row?.professionalSpecialities ||
      !row?.clinicAddresses?.[0]?.city ||
      !row?.formation
    ) {
      return "Cadastro Incompleto";
    }
    return row?.professionalsPlans?.[0]?.isActive ? "Ativo" : "Inativo";
  };

  // Função para criar o arquivo Excel e iniciar o download
  const downloadExcel = () => {
    console.log(csvExport)
    const ws = XLSX.utils.aoa_to_sheet(csvExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "backoffice-minhafono.xlsx");
  };

  return (
    <Grid container={true} spacing={6}>
      <Grid item={true} xs={12}>
        <Paper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>
              <InputLabel id="demo-multiple-checkbox-label">
                Pesquisar
              </InputLabel>
              <TextField
                inputProps={{
                  autoComplete: "off",
                }}
                type="text"
                autoComplete="off"
                label="Pesquisar"
                variant="outlined"
                onChange={handlerOnBlur}
              />
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <InputLabel id="demo-multiple-checkbox-label">Filtro</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>

              <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                value={[getStatusLabel, active]}
                onChange={handleChangeStatus}
              >
                <MenuItem value={"findAll"}>Todos</MenuItem>
                <MenuItem value={"true"}>Ativo</MenuItem>
                <MenuItem value={"false"}>Inativo</MenuItem>
              </Select>
            </span>
          </div>

          {csvExport.length !== 0 ? (
            <button onClick={downloadExcel} style={{ float: "right" }}>
              Download Excel
            </button>
          ) : (
            ""
          )}

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">{`Deseja excluir ${name}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza que deseja excluir?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemove} color="primary">
                Sim
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus={true}>
                Não
              </Button>
            </DialogActions>
          </Dialog>

          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableCell style={{ width: "70px" }}>Nome / Usuário</TableCell>
                <TableCell style={{ width: "70px" }}>Especialidade</TableCell>
                <TableCell>Área</TableCell>
                <TableCell>Cidade</TableCell>
                <TableCell>Tipos de tendimento</TableCell>
                <TableCell>Plano</TableCell>
                <TableCell align="center">Criação / Atualização</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableHead>
              <TableBody>
                {data?.results
                  ?.map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover={true}
                        key={`${row.id}-${index}`}
                        tabIndex={1}
                      >
                        <TableCell>
                          <Typography>
                            {row?.name}
                            <br />
                            E-mail: {row?.email}
                            <br />
                            CPF/CNPJ: {row?.cpf_cnpj ? row?.cpf_cnpj : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Customer>
                            <Typography>
                              {row?.professionalSpecialities
                                ?.sort(function (a: any, b: any) {
                                  if (a.speciality?.name < b.speciality?.name) {
                                    return -1;
                                  }
                                  if (a.speciality?.name > b.speciality?.name) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((s: any) => {
                                  if (
                                    s?.speciality?.attendenceMethod?.name ===
                                    "PRESENCIAL"
                                  ) {
                                    return (
                                      <Chip label={`${s?.speciality?.name}`} />
                                    );
                                  }
                                })}
                            </Typography>
                          </Customer>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Customer>
                            <Typography>
                              {row?.formation ? row?.formation : "-"}
                            </Typography>
                          </Customer>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Customer>
                            <Typography>
                              {"-"}
                            </Typography>
                          </Customer>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Customer>
                            <Typography>
                              {row?.professionalAttendenceMethods?.map(
                                (p: any) => {
                                  return (
                                    <Chip
                                      label={`${p?.attendenceMethod?.name}`}
                                    />
                                  );
                                }
                              )}
                            </Typography>
                          </Customer>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <small>
                              <Chip
                                style={{
                                  background:
                                    `${row?.status}` === `Cadastro Completo`
                                      ? "green"
                                      : "red",
                                  color: "white",
                                }}
                                label={"Cadastro Incompleto"}
                              />
                              <br />
                              {
                                // row?.professionalsPlans[0]?.date_due ? ` Expira: ` + moment(row?.professionalsPlans[0]?.date_due).format('D/M/YYYY') : '-'
                              }
                            </small>
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <MFDateFormat date={row.createdAt} />
                            <br />
                            {row.updatedAt && (
                              <MFDateFormat date={row.updatedAt} />
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            component={NavLink}
                            exact={true}
                            to={`/professionals/${row.id}/edit`}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={(id) =>
                              handleRemoveAction(row.id, row.name)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                  .sort()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={data?.totalResults}
            rowsPerPage={20}
            page={page}
            onChangePage={handleChangePage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export const ProfessionalIndexIncompletePage = () => {
  return (
    <ProtectedPage
      title="Profissionais"
      breadcrumbs={[{ name: "Profissionais", to: "/professionals" }]}
      action={
        <Button
          color="primary"
          component={NavLink}
          exact={true}
          startIcon={<AddIcon />}
          to="/professionals/new"
          variant="contained"
        >
          Cadastrar Profissional
        </Button>
      }
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
