import { AttendenceMethodsService } from "../../services";
import { AttendenceMethodsForm, ProtectedPage } from "../../components";
import { useParams } from "react-router-dom";
import React from "react";

export const AttendenceMethodsEditFormPage = () => {
  const [data, setData] = React.useState([]);
  const { id } = useParams<{ id: any }>();

  React.useEffect(() => {
    (async () => {
      const resList = await AttendenceMethodsService.getById(id);
      setData(resList.data);
    })();
  }, []);

  const handleSubmit = async (form: any) => {
    await AttendenceMethodsService.edit(id, form);
  };

  return (
    <ProtectedPage
      title="Editar Método de Atendimento"
      breadcrumbs={[
        { name: 'Métodos de Atendimento', to: '/attendence-methods' },
        { name: 'Editar Métodos de Atendimento', to: '/attendence-methods/:id/edit' }
      ]}
    >
      <AttendenceMethodsForm
        data={data}
        buttonName="Salvar"
        formTitle="Editar Método de Atendimento"
        handleSubmit={handleSubmit}
        resetFields={false}
        successMessage="Método de Atendimento Atualizado com Sucesso!"
      />
    </ProtectedPage>
  );
};
