import { NavLink } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import axios from 'axios';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  Add as AddIcon,
  ListSharp,
  Delete as DeleteIcon,
  Edit as EditIcon,
  VisibilityOutlined,
  VisibilityOff
} from "@material-ui/icons";
import { ProtectedPage, CardComponent } from "../../components";
import { VideosService } from "../../services";

import { spacing } from "@material-ui/system";

import "../../styles/main.css";

import Swal from 'sweetalert2';

const Paper = styled(MuiPaper)(spacing);

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

let id: any = 0;

function EnhancedTable() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState<any>({});
  const [deleteId, setDeleteId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);

  const hiddenFilePictureInput: any = React.useRef(null);


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await VideosService.deleteById(deleteId);
    handleClose();
    const resList = await VideosService.list(page + 1, 6);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any) => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await VideosService.list(page + 1, 6);
      setData(resList.data);
    })();
  }, [page]);

  const handlePictureClick = (e: any, identification: any) => {
    id = identification
    hiddenFilePictureInput.current?.click();
  }

  const onFilePictureChange = (e: any) => {
    let files = e.target.files;
    let fileReader = new FileReader();

    fileReader.readAsDataURL(files[0]);

    fileReader.onload = (event: any) => {
      submitPictureForm(files[0], id)
    }
  }

  const submitPictureForm = async (event: any, id: any) => {

    let formData = new FormData();
    formData.append("fileToUpload", event);

    let endpoint = "https://files.minhafono.com.br/upload-pictures.php";
    axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then(async (res: any) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          VideosService.update(id, { thumbnail: 'https://files.minhafono.com.br/' + res.data });
        },
        willClose: async () => {
          const resList = await VideosService.list(page + 1, 6);
          setData(resList.data);
        },
      });

      Toast.fire({
        icon: 'success',
        title: 'Foto de capa atualizado!',
      });

    })
  };


  return (
    <Grid container={true} spacing={6}>
      <Grid item={true} xs={12}>
        <Paper>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">{`Alerta!`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Tem certeza que deseja excluir?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRemove} color="primary">
                Sim
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus={true}>
                Não
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableCell style={{ width: '150px' }}></TableCell>
                <TableCell style={{ width: '500px' }}>Vídeo</TableCell>
                <TableCell style={{ width: '200px' }}>Visibilidade</TableCell>
                <TableCell style={{ width: '200px' }}>Data</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableHead>
              <TableBody>
                {data?.results?.map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover={true}
                      key={`${row.id}-${index}`}
                      tabIndex={1}
                    >
                      <TableCell>
                        <form style={{ display: "none" }}>
                          <input type="file" name="file" onChange={onFilePictureChange} ref={hiddenFilePictureInput} ></input>
                        </form>
                        <Customer onClick={(e) => handlePictureClick(e, row.id)} style={{ cursor: "pointer" }}>
                          <CardComponent
                            url={row?.thumbnail}
                          />
                        </Customer>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Customer>
                          <Typography>
                            <strong>{row?.title}</strong>
                            <br />
                            <small>
                              {row?.description?.length <= 25 && <span dangerouslySetInnerHTML={{ __html: row?.description }} />
                              }
                              {row?.description?.length > 200 && <span title={row?.description}>

                                <span dangerouslySetInnerHTML={{ __html: row?.description.substr(0, 200) }} />
                              </span>}
                            </small>
                          </Typography>
                        </Customer>
                      </TableCell>
                      <TableCell align="left">
                        <Typography className="main">
                          {row?.status === "Público" ? (<VisibilityOutlined color="primary" />) : (<VisibilityOff color="error" />)} &nbsp;  {row?.status}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>
                          <small>{row?.createdAt}<br />Publicado</small>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="edit"
                          component={NavLink}
                          exact={true}
                          to={`/videos/${row.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={(id) => handleRemoveAction(row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {<TablePagination
            rowsPerPageOptions={[6]}
            component="div"
            count={data?.totalResults}
            rowsPerPage={6}
            page={page}
            onChangePage={handleChangePage}
          />}
        </Paper>
      </Grid>
    </Grid>
  );
}

export const VideosIndexPage = () => {

  const hiddenFileVideosInput: any = React.useRef(null);

  const handleVideosClick = (e: any) => {
    hiddenFileVideosInput.current?.click();
  }

  const onFileVideoChange = (e: any) => {
    let files = e.target.files;
    let fileReader = new FileReader();

    fileReader.readAsDataURL(files[0]);

    fileReader.onload = (event: any) => {
      submitVideoForm(files[0])
    }
  }


  const submitVideoForm = async (event: any) => {
    const toastLoad = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast'
      },
      timerProgressBar: true,
      didOpen: (toast) => {

      },
      willClose: () => {

      },
    });


    let formData = new FormData();
    formData.append("fileToUpload", event);

    let percentCompleted = 0;
    let endpoint = "https://files.minhafono.com.br/upload-videos.php";

    axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        return (
          toastLoad.fire({
            icon: 'info',
            title: 'Carregando vídeo... ' + percentCompleted + '%',
          })
        );
      }
    }).then((res: any) => {
      toastLoad.close();

      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          VideosService.create({ path: 'https://files.minhafono.com.br/' + res.data, status: 'Privado' })
        },
        willClose: () => {
          window.location.reload();
        },
      });

      Toast.fire({
        icon: 'success',
        title: 'Vídeo carregado com sucesso!',
      });


    })
  };

  return (
    <ProtectedPage
      title="Vídeos"
      breadcrumbs={[{ name: "Videos", to: "/videos" }]}
      action={
        <Button onClick={handleVideosClick} color="primary" startIcon={<AddIcon />} variant="contained">
          Enviar Vídeo
        </Button>
      }
      add={
        <Button
          component={NavLink}
          exact={true}
          to={`/videos/playlists`} color="primary" startIcon={<ListSharp />} variant="contained">
          Playlists
        </Button>
      }
    >
      <form style={{ display: "none" }}>
        <input type="file" name="file" onChange={onFileVideoChange} ref={hiddenFileVideosInput}></input>
      </form>
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
