import { SignInType } from "../types/auth";
import { baseURL } from "./BackendService";
import axios from "axios";

const setAuth = (response: any) => {
  if (response.data) {
    localStorage.setItem("auth", JSON.stringify(response.data));
  }

  return response.data;
};

export const signIn = (credentials: SignInType) =>
  axios.post(`${baseURL}/backoffice/auth/login`, credentials).then(setAuth);

export const refreshToken = (refreshToken: string) =>
  axios
    .post(`${baseURL}/backoffice/auth/refresh-token`, {
      refreshToken,
    })
    .then(setAuth);

export const signOut = () => {
  localStorage.removeItem("auth");
};

export const getCurrentUser = async () => {
  const authJson = await localStorage.getItem("auth");
  if (authJson) {
    return JSON.parse(authJson);
  }
  return null;
};
