import { InsurancesForm, ProtectedPage } from "../../components";
import { InsurancesService } from "../../services";


export const InsurancesRegisterFormPage = () => {

  const handleSubmit = async (form: any) => {
    await InsurancesService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Plano de Saúde"
      breadcrumbs={[
        { name: 'Planos de Saúde', to: '/insurances' },
        { name: 'Cadastrar Plano de Saúde', to: '/insurances/new' }
      ]}
    >
      <InsurancesForm
        buttonName="Salvar"
        formTitle="Cadastrar Plano de Saúde"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Plano de Saúde Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
