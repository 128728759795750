import { RatingsService } from "../../services";
import { ProtectedPage, MFDateFormat } from "../../components";
import { spacing } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  Delete as DeleteIcon,
} from "@material-ui/icons";

const Paper = styled(MuiPaper)(spacing);

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-right: 16px;
`;

const AdminUser = styled.div`
  align-items: center;
  display: flex;
`;

function EnhancedTable() {
  const [data, setData] = React.useState<any>();
  const [deleteId, setDeleteId] = React.useState<any>();
  const [name, setName] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRemove = async () => {
    await RatingsService.deleteById(deleteId);
    handleClose();
    const resList = await RatingsService.list(page + 1, 6);
    setData(resList.data);
  };

  const handleRemoveAction = (id: any, name: any) => {
    setOpen(true);
    setDeleteId(id);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  React.useEffect(() => {
    (async () => {
      const resList = await RatingsService.list(page + 1, 6);
      setData(resList.data);
    })();
  }, [page]);

  return (
    <div>
      <Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">{`Deseja excluir o comentário de ${name}?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja excluir o comentário?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove} color="primary">
              Sim
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus={true}>
              Não
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell >Nome</TableCell>
                <TableCell align="center">Nota</TableCell>
                 <TableCell>Comentário</TableCell>
                <TableCell align="center">Criação</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.items?.map((row: any, index: number) => {
                return (
                  <TableRow
                    hover={true}
                    key={`${row.id}-${index}`}
                    tabIndex={-1}
                  >
                    <TableCell component="th" scope="row">
                      <AdminUser>
                        <Avatar />
                        <Typography>
                          {row.name} {row.lastname}
                        </Typography>
                      </AdminUser>
                    </TableCell>
                     <TableCell>
                      <Typography align="center">
                        {row.rate}
                      </Typography>
                    </TableCell>
                      <TableCell>
                      <Typography>
                        {row.comment.length <= 25 && <p>
                          {row.comment}</p>
                        }
                        {row.comment.length > 25 && <abbr title={row.comment}>
                          {row.comment.substr(0, 25) + "..."}
                        </abbr>}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center">
                        <MFDateFormat date={row.createdAt} />
                        <br />
                        {row.updatedAt &&
                          <MFDateFormat date={row.createdAt} />}
                      </Typography>
                    </TableCell>
                   
                    <TableCell align="right" >
                      <IconButton
                        aria-label="delete"
                        onClick={(id) => handleRemoveAction(row.id, row.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[6]}
          component="div"
          count={data?.meta?.totalItems}
          rowsPerPage={6}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}

export const RatingsIndexPage = () => {
  return (
    <ProtectedPage
      title="Comentário"
      breadcrumbs={[{ name: "Comentários", to: "/ratings" }]}
    >
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </ProtectedPage>
  );
};
