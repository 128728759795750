import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextProps {
    value: string;
    onChange: (e: any) => void;
}

export const RichText: React.FC<RichTextProps> = ({
    value,
    onChange
}) => {
    const modules = {
        toolbar: {
            container: [
                [{ 'size': [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'script': 'super' }, { 'script': 'sub' }],
                [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['direction', { 'align': [] }],
                ['link', 'image', 'video'],
                ['clean']
            ]
        }
    };

    return (
        <ReactQuill theme="snow"
            value={value}
            readOnly={false}
            onChange={onChange}
            modules={modules} />
    );
}
