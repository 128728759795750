import { Alert as MuiAlert } from "@material-ui/lab";
import { MFTextField } from "../components";
import { SpecialityService } from "../services";
import { spacing, SpacingProps } from "@material-ui/system";
import React from "react";
import styled from "styled-components";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper as MuiPaper,
  Radio,
  Typography,
} from "@material-ui/core";


const Alert = styled(MuiAlert)(spacing);

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

interface ButtonPropstype extends SpacingProps {
  component?: string;
}

interface AttendencePlataformFormProps {
  buttonName: string;
  data?: any;
  formTitle: string;
  handleSubmit: any;
  resetFields: boolean;
  successMessage: string;
}

export const AttendencePlataformForm: React.FC<AttendencePlataformFormProps> = ({
  buttonName,
  data,
  formTitle,
  handleSubmit,
  resetFields,
  successMessage,
}) => {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [status, setStatus] = React.useState({sent: false});
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    setName(data?.name || "");
  }, [data]);

  const resetForm = () => {
    setName(""); 
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    const formData = {
      name,
    };

    setSubmitting(true);

    try {
      await handleSubmit(formData);
      if (resetFields) {
        resetForm();
      }
      setStatus({ sent: true });
      setSubmitting(false);
      setErrors({});
    } catch (err: any) {
      if (err?.response?.status === 400) {
        setErrors(err?.response?.data);
      }

      setStatus({ sent: false });
      setSubmitting(false);
    }
  };

  const onNameChange = (event: any) => setName(event.target.value);

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h4" gutterBottom={true}>
          {formTitle}
        </Typography>

        {status && status.sent && (
          <Alert severity="success" my={3}>
            {successMessage}
          </Alert>
        )}

        {isSubmitting ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid item={true} md={6} style={{ marginBottom: "16px" }}>
                <MFTextField
                  error={!!errors?.name}
                  fullWidth={true}
                  helperText={errors?.name}
                  label="Nome da Plataforma de Atendimento"
                  my={2}
                  name="name"
                  onChange={onNameChange}
                  placeholder="Digite o nome da Plataforma de Atendimento"
                  value={name}
                  variant="outlined"
                />
              </Grid>
    
              <Button
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="primary"
                mt={4}
              >
                {buttonName}
              </Button>
            </form>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
};
