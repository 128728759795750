import { PlaylistForm, ProtectedPage } from "../../components";
import { PlaylistsService } from "../../services";

export const PlaylistRegisterFormPage = () => {
  const handleSubmit = async (form: any) => {
    await PlaylistsService.create(form);
  };

  return (
    <ProtectedPage
      title="Cadastrar Playlist"
      breadcrumbs={[
        { name: "Vídeos", to: "/videos" },
        { name: "Playlist", to: "/videos/playlists" },
        { name: "Cadastrar Playlist", to: "/videos/playlists/new" },
      ]}
    >
      <PlaylistForm
        buttonName="Salvar"
        formTitle="Cadastrar Playlist"
        handleSubmit={handleSubmit}
        resetFields={true}
        successMessage="Playlist Cadastrado com Sucesso!"
      />
    </ProtectedPage>
  );
};
